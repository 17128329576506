import React from 'react';

import Layout from '../components/layout';
import MissionCards from '../components/MissionCards';
import Seo from '../components/Seo';

const ActivitiesPage = ({ location }) => (
  <Layout page="activities" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Activities',
        seoDescription:
          'AquaSmart activities provide a fun way of learning water saving tips in the form of missions',
      }}
    />
    <section className="aq-feature-bg pt-8 pb-8 grey-overlay">
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-6 pb-4 pb-lg-0">
              <h1 className="h1-two-line-v2 green mb-8 mb-lg-16 mt-6">
                <span>Activities</span>
              </h1>
              <div
                style={{
                  position: 'absolute',
                  top: '80px',
                  left: '400px',
                  height: '100px',
                  width: '100px',
                  transform: 'rotate(180deg)',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Arrows/Arrow-3.svg"
                  alt="Illustration of an arrow"
                />
              </div>
            </div>
            <div className="col-lg-6 pb-4 pb-lg-0">
              <div
                style={{
                  position: 'absolute',
                  top: '50px',
                  left: '450px',
                  height: '135px',
                  width: '135px',
                  transform: 'rotate(340deg)',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Stars/Star-2.svg"
                  alt="Illustration of asterisks"
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '100px',
                  left: '550px',
                  height: '135px',
                  width: '135px',
                  transform: 'rotate(20deg)',
                }}
                className="d-none d-lg-block"
              >
                <img
                  height="100px"
                  src="/images/Doodles/Exclamations/Exclamation-6.svg"
                  alt="Illustration of punctuation"
                />
              </div>
            </div>
          </div>
          <MissionCards />
        </div>
      </div>
    </section>
  </Layout>
);

export default ActivitiesPage;
