import React from 'react';
import { Link } from 'gatsby';

const MissionCards = () => (
  <div className="row px-xl-8 gx-3">
    <div className="col-lg-4 mb-4 mb-lg-0">
      <div className="aq-card p-3 pb-4 bg-turquoise">
        <h3 className="large-v2 pink">
          <span>Mission</span>
          <br />
          <span>1</span>
        </h3>
        <div className="image-position narrow-mobile">
          <div
            className="image"
            style={{
              backgroundImage: `url(/images/Characters/Large-Card-1.svg)`,
            }}
          />
        </div>
        <div className="px-2">
          <p>5 activities and a quiz to test what you have learnt.</p>
          <Link className="btn btn-pink" to="/activities/mission-1">
            Mission 1
          </Link>
        </div>
      </div>
    </div>
    <div className="col-lg-4 mb-4 mb-lg-0">
      <div className="aq-card p-3 pb-4 bg-turquoise">
        <h3 className="large-v2 purple">
          <span>Mission</span>
          <br />
          <span>2</span>
        </h3>
        <div className="image-position">
          <div
            className="image"
            style={{
              backgroundImage: `url(/images/Characters/Large-Card-3.svg)`,
            }}
          />
        </div>
        <div className="px-2">
          <p>5 activities and a quiz to test what you have learnt.</p>
          <Link className="btn btn-pink" to="/activities/mission-2">
            Mission 2
          </Link>
        </div>
      </div>
    </div>
    <div className="col-lg-4 mb-4 mb-lg-0">
      <div className="aq-card p-3 pb-4 bg-turquoise">
        <h3 className="large-v2 teal">
          <span>Mission</span>
          <br />
          <span>3</span>
        </h3>
        <div className="image-position">
          <div
            className="image"
            style={{
              backgroundImage: `url(/images/Characters/Large-Card-2.svg)`,
            }}
          />
        </div>
        <div className="px-2">
          <p>5 activities and a quiz to test what you have learnt.</p>
          <Link className="btn btn-pink" to="/activities/mission-3">
            Mission 3
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default MissionCards;
